$(document).ready(function() {
    'use strict';
  
    function submitUserUpdateForm(form, event) {
      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        dataType: 'json',
        beforeSend: function(data) {
          form.find(':input').prop('disabled', true);
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
          } else {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        },
        complete: function(data) {
          form.find(':input').prop('disabled', false);
        }
      });
    }

    $('#user-update-data-form').submit(function(event) {
        event.preventDefault();
        var form = $(this);
        submitUserUpdateForm($(form), event);
    });

    $('#user-update-dispatch-form').submit(function(event) {
        event.preventDefault();
        var form = $(this);
        submitUserUpdateForm($(form), event);
    });

    $('#user-update-invoice-form').submit(function(event) {
        event.preventDefault();
        var form = $(this);
        submitUserUpdateForm($(form), event);
    });

  
    $('#password-change-form').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function(data) {
              $(this).find(':input').prop('disabled', true);
            },
            success: function(data) {
              if (data.success) {
                showMessageBox('success', data.data);
              } else {
                showMessageBox('danger', data.data);
              }
            },
            complete: function(data) {
              $(this).find(':input').prop('disabled', false);
            }
        });
    });



    // profile dropdown
    $.fn.showControls = function() {
        return this.each(function() {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).show();
            $this.addClass('selected');
            $this.find('.order-row-indicator').addClass('fa-rotate-90');
        });
    };

    $.fn.hideControls = function() {
        return this.each(function() {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).hide();
            $this.removeClass('selected');
            $this.find('.order-row-indicator').removeClass('fa-rotate-90');
        });
    };

    $('.order-row').on('click', function(event) {
        var $this = $(this);
        if ($this.hasClass('selected')) {
            $this.hideControls();
        } else {
            $this.parent().find('.order-row.selected').hideControls();
            $this.showControls();
            $('html, body').animate({
                scrollTop: $this.position().top
            }, 200);
        }
    });

    $('.edit-order').on('click', function(event) {
        var $this = $(this);
        $.post('/restful/delegate/easydropshipping/bookedtocart',
            { orderId: $this.attr('data-order-id') },
            function (data, textStatus, jqXHR) {
                if (data.success) {
                    window.location = '/current/cart';
                } else {
                    alert('Cannot edit this order.');
                }
            }
        );
    });

    $('.show-return-form').click(function(event) {
        event.preventDefault();
        var $this = $(this);
        var target = $this.attr('data-target');

        $(target).toggle();
    });

    $('.new-return-request form').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $(this).prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RETURN_REQUEST_SUCCESS']);
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $(this).prop('disabled', false);
            }
        });
    });

    $('form.cancel-return-request-form').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/profile/returnrequests/1';
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
  });