$(document).ready(function() {

    $('.main-menu-item > a').on('click', function() {
        $('.main-menu-dropdown').hide();
        $(this).next().toggle();
    });

    $('.js-open-menu').on('click', function() {
        $('.main-menu').show();
        $('html, body').addClass('is-popup-open');
    });
    $('.js-close-menu').on('click', function() {
        $('.main-menu').hide();
        $('html, body').removeClass('is-popup-open');
    });

    $(window).scroll(function() {
        if ($(this).scrollTop() > 34) {
            $('header').addClass("sticky");
        }
        else {
            $('header').removeClass("sticky");
        }
    });

});