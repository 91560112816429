$(document).ready(function() {
    'use strict';

    $('#password-recover-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
                showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
            }
        });
    });

    $('#subscribe-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    window.location = $form.data('redirectsuccess');
                } else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    });

    // newsletter show recapcha
    $('#mail2').on('focus', function() {
        $('#newsletter-step2').fadeIn(1300);
    });

    // newsletter
    $('#subscribe-newsletter').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success) {
                    $('#newsletter-step2').hide();
                    $('#mail2').val('');
                    $('#success-message').show();
                }
                else {
                    showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });

});


function subscribeNewsletterCaptcha() {
    $('#subscribe-newsletter button[type=submit]').prop('disabled', false);
}