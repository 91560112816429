$(document).ready(function() {

    $('#go-reset').on('click', function() {
        $('#login-block').hide();
        $('#reset-block').fadeIn(1300);
    });

    $('#back-login').on('click', function() {
        $('#reset-block').hide();
        $('#login-block').fadeIn(1300);
    });

});