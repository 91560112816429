$(document).ready(function() {
    'use strict';

    function addClickEvent() {
      $('.js-open-filter').on('click', function() {
        $('#filter-list').addClass('filter-open');
        $('html, body').addClass('is-popup-open');
      });
      $('.js-close-filter').on('click', function() {
        $('#filter-list').removeClass('filter-open');
        $('html, body').removeClass('is-popup-open');
      });
    }

  
    function populateRemoveFilters() {
      $('#filter-list li.active > a').each(function() {
        var id = $(this).data('ref');
        var url = $(this).attr('href');
        $(document.getElementById(id)).attr('href', url);
      });
      $('.current-filters > a').each(function() {
        if ($(this).attr('href') === '#') {
          $(this).addClass('disabled');
          $(this).children('span.fa').remove();
        }
      });
    }
  
  
    function reloadCatalog(url, push) {
      $('#catalog-container').addClass('catalog-loading');
      $('#catalog-container').load(url + ' #catalog-wrapper', function() {
        if (push) {
          window.history.pushState(null, document.title, url);
        }
        populateRemoveFilters();
        addClickEvent();
        $('#catalog-container').removeClass('catalog-loading');
        $('#filter-list').removeClass('filter-open');
        $('html, body').removeClass('is-popup-open');
      });
    }

  
    function updateCartCount(form) {
      var previous = parseInt($('.cart-button span.count').html());
      var addedProducts = 0;
      form.find('input[type=text]').each(function() {
        addedProducts += $(this).val() - $(this).data('qtyorig');
        $(this).data('qtyorig', $(this).val());
      });
      var newQuantity = previous + addedProducts;
      $('.cart-button span.count').html(newQuantity);
    }

  
    $(window).on('popstate', function(event) {
      reloadCatalog(window.location, false);
    });
  
    $('#catalog-container').on('click', '#filter-list li > a', function(event) {
      event.preventDefault();
      var count = $('#filter-list li.active').length;
      if (count >= 8 && !$(this).parent().hasClass('active')) {
        showMessageBox('warning', general_toomanyfilters);
        $('#filter-list').focus();
      } else {
        var url = $(this).attr('href');
        reloadCatalog(url, true);
      }
    });
  
    $('#catalog-container').on('click', '.current-filters a', function(event) {
      event.preventDefault();
      var url = $(this).attr('href');
      reloadCatalog(url, true);
    });
  
    $('#catalog-container').on('click', '#filter-list .toggle-subfilter', function(event) {
      event.preventDefault();
      $(this).parent('li').toggleClass('open');
    });
  
    $('#catalog-container').on('click', '#toggle-filters-button', function(event) {
      event.preventDefault();
      $(this).find('.fa').toggleClass('fa-rotate-180x');
      $('#filters-dropdown').slideToggle();
    });
  
    $('#catalog-container').on('click', '#layout-options input[type="radio"]', function(event) {
      setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
    });
  
    $('#product-container .sizes input.size').on('click', function(event) {
      var input = $(this);
      // set 0 to all the other values
      input.closest('form').find('input[type=hidden].size-qty-input').each(function() {
        $(this).val($(this).data('qtyorig'));
      });
      // set for the selected input size the value 1
      var target = $(input.data('target'));
      target.val(parseInt(target.data('qtyorig')) + 1);
    });
  
    //Add to cart code
    $('#catalog-container, #product-container').on('submit', '.addtocart-form', function(event) {
      event.preventDefault();
      var form = $(this);
      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        headers: {'Accept': 'application/json'},
        success: function(data) {
          if (!data.success) {
            if (data.code == 'MIXED_SALE_CART') {
              showMessageBox('danger', msg['MSG_MIXED_SALE_CART']);
            } else if (data.code == 'MIXED_CART') {
              showMessageBox('danger', msg['MSG_MIXED_CART']);
            } else if (data.code == 'LOGIN_REQUIRED') {
              location.href = '/' + $('html').attr('lang') + '/login';
            } else {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
          } else {
            showMessageBox('success', msg['MSG_ADDEDTOCART'], {
              content: '<a class="btn" href="/current/cart"><span class="fa fa-shopping-cart fa-spacing" />' +
              buttons_gocheckout + '</a>'
            });
            rewixUpdateCart(form);
            updateCartCount(form);
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
        }
      });
    });
  
    populateRemoveFilters();
    addClickEvent();
  
  });