$(document).ready(function() {

    function submitAddressEditForm(form) {
        var href = form.data('nextstep');
        return $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success && data.data === 'VAT_CHANGED') {
                    href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }




    var dispatchForm = $('#dispatch-address-form');

    $('#dispatch-form-btn').on('click', function(event) {
        event.preventDefault();
        dispatchForm.foundation("validateForm");
    });
    dispatchForm.bind("forminvalid.zf.abide", function(e,target) {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    });
    dispatchForm.bind("formvalid.zf.abide", function(e,target) {
        $.when( submitAddressEditForm(dispatchForm) ).done(function( x ) {
            window.location = "/current/checkout/billing";
        }); 
    });




    var billingForm = $('#billing-address-form');

    $('#billing-form-btn').on('click', function(event) {
        event.preventDefault();

        if (!$('#clausola1').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
            $('#clausola1 + label').addClass('is-invalid-label');
            return;
        }

        billingForm.foundation("validateForm");
    });
    billingForm.bind("forminvalid.zf.abide", function(e,target) {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    });
    billingForm.bind("formvalid.zf.abide", function(e,target) {
        $.when( submitAddressEditForm(billingForm) ).done(function( x ) {
            window.location = "/current/checkout/payment";
        }); 
    });

});