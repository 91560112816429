$(document).ready(function() {
    $('.i-number').inputNumber({
        onChange: function(object) {
          $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
    });


    // FIXME: from popover and cart button there should be a different behavior
    $(document).on('click', '.button-cart-remove', function(event) {
        event.preventDefault();
        var data = { };
        var $this = $(this);
        data[$this.data('name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.data('action'),
            data: data,
            dataType: 'json',
            success: function(data){
                if (!data.success) {
                    if (data.code === 'MIXED_SALE_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_SALE_CART']);
                    } else if (data.code === 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    } else if (data.code === 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    } else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                } else {
                    const container = $(this).closest('form, .off-cart-product');
                    rewixUpdateCart(container, true);
                    updateCartCount(window.location.href, function() {
                        if ($this.data('refresh'))
                            location.reload();
                    });

                    if (window.location.href.indexOf('catalog') > 0) {
                        updateAddToCart(window.location.href);
                    }
                }
            },
            error: function(xhr, textStatus, errorThrown){
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    $('.shop-product').on('submit', '.addtocart-form', function(event) {
      event.preventDefault();
      var form = $(this);
      var selected = '#' + $('.product-sizes input:checked').attr('id');

      if (selected === '#no-size-selected') {
          showMessageBox('danger', msg['ERR_NOSIZESELECTED']);
      }
      else {
          $.ajax({
              type: form.attr('method'),
              url: form.attr('action'),
              data: form.serialize(),
              headers: {'Accept': 'application/json'},
              beforeSend: function(data) {
                  $('.btn-loader').show();
              },
              success: function(data) {
                  if (!data.success) {
                      if (data.code === 'MIXED_SALE_CART') {
                          showMessageBox('danger', msg['MSG_MIXED_SALE_CART']);
                      } else if (data.code === 'MIXED_CART') {
                          showMessageBox('danger', msg['MSG_MIXED_CART']);
                      } else if (data.code === 'LOGIN_REQUIRED') {
                          location.href = '/' + $('html').attr('lang') + '/login';
                      } else {
                          showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                      }
                  } else {
                      rewixUpdateCart(form);
                      updateCartCount(window.location.href, function() {
                          $('#offCanvasCart').foundation('open', event, form);
                      });

                      if (window.location.href.indexOf('catalog') > 0) {
                          updateAddToCart(window.location.href, function() {
                              $(selected).click();
                          });
                      }
                  }
              },
              error: function(xhr, textStatus, errorThrown) {
                  showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
              },
              complete: function(data) {
                  $('.btn-loader').hide();
              }
          });
      }


    });
});

function preventNotNumericValues(e) {
    var charCode = (e.which) ? e.which : e.keyCode;
  
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(charCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      (charCode === 65 && ( e.ctrlKey === true || e.metaKey === true ) ) ||
      (charCode >= 35 && charCode <= 40)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
      e.preventDefault();
    }
}

function InputNumber(option) {
    return this.each(function() {
      var $this = $(this);
  
      $this.keydown(preventNotNumericValues);
      $this.keyup(function() {
        option.onChange($this.get(0));
      });
  
      $this.max = $this.attr('data-max');
      $this.min = $this.attr('data-min');
  
      $('<a class="i-number-minus" href="#" role="button" tabindex="-1"><span class="ei-icon_minus-06"></span></a>')
        .insertBefore($this)
        .on('click', function(event) {
          event.preventDefault();
          var val = parseInt($this.val());
          var min = $this.min || 0;
          if ($.isNumeric(val) && val > min) {
            $this.val(val - 1);
          } else {
            $this.val(0);
          }
          option.onChange($this.get(0));
        });
      $('<a class="i-number-plus" href="#" role="button" tabindex="-1"><span class="ei-icon_plus"></span></a>')
        .insertAfter($this)
        .on('click', function(event) {
          event.preventDefault();
          var val = parseInt($this.val());
          var max = $this.max || Number.MAX_VALUE;
          if (!$.isNumeric(val)) {
            $this.val(0);
          } else if (val >= $this.min && val < max) {
            $this.val(val + 1);
          }
          option.onChange($this.get(0));
        });
    });
}

$.fn.inputNumber = InputNumber;

function updateAddToCart(url, callback) {
  $('.addtocart-form-container').load(url + ' .addtocart-form', function() {
      if (typeof callback !== 'undefined') {
          callback();
      }
  });
}

function updateCartCount(url, callback) {
  $('#offCanvasCart').load(url + ' #offCanvasCartContainer', function() {
      if (typeof callback !== 'undefined') {
          callback();
      }
  });
}